import React, { ReactElement } from "react"
import { GatsbyBrowser } from "gatsby"
import ApplicationLayout from "../../src/components/layouts/ApplicationLayout"
import AdminLayout from "../../src/components/layouts/AdminLayout"
import OnboardingLayout from "../../src/components/layouts/OnboardingLayout"
import AccountLayout from "../../src/components/layouts/AccountLayout"
import { PathProvider } from "../../src/providers/PathProvider"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  const path = props.location.pathname.replace(new RegExp(/(^\/)|(\/$)/g), "")

  let layout: ReactElement

  if (path.match(/^application/)) {
    layout = <ApplicationLayout>{element}</ApplicationLayout>
  } else if (path.match(/^account/)) {
    layout = <AccountLayout>{element}</AccountLayout>
  } else if (path.match(/^admin/)) {
    const hideHomeLink = path === "admin"
    layout = <AdminLayout showHomeLink={!hideHomeLink}>{element}</AdminLayout>
  } else {
    const hideLinks = path === "" || path === "sign-in"
    layout = (
      <OnboardingLayout hideLinks={hideLinks}>{element}</OnboardingLayout>
    )
  }

  return <PathProvider path={path}>{layout}</PathProvider>
}
