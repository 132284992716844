import React, { ElementType } from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import T, { TypographyProps } from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import Link from "./Link"
import RawLogo from "./Logo"
import { email } from "@ap-frontend/questions"
import VersionPill from "./VersionPill"
import { FooterLandmark } from "./layouts/Landmarks"

export const WhiteT = styled(T)<TypographyProps & { component?: ElementType }>(
  ({ theme }) => ({
    color: theme.palette.common.white,
  })
)

const Logo = styled(RawLogo)(({ theme }) => ({
  maxWidth: 152,
  marginBottom: theme.spacing(2),
}))

const links: {
  href?: string
  label: string
  to?: string
}[] = [
  {
    href: "https://www.information-compliance.admin.cam.ac.uk/data-protection/applicant-data",
    label: "Data and privacy policy",
  },
  {
    to: "/accessibility",
    label: "Accessibility statement",
  },
  {
    to: "/terms-and-conditions",
    label: "Terms and conditions",
  },
  {
    to: "/confidentiality",
    label: "Confidentiality",
  },
]

export const MinimalFooter: React.FC = () => {
  return (
    <FooterLandmark>
      <WhiteT variant="subtitle1" component="p" sx={{ mb: 0 }}>
        My Cambridge Application <VersionPill />
      </WhiteT>
    </FooterLandmark>
  )
}

const betweenColumnsPadding = 3

const Footer: React.FC = () => {
  return (
    <FooterLandmark>
      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ pr: [0, 0, betweenColumnsPadding], pb: [1, 1, 0] }}
        >
          <Logo color="white" />
          <WhiteT variant="subtitle1" component="p">
            &copy; Copyright University of Cambridge
          </WhiteT>
          <VersionPill />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          sx={{
            pl: [0, 0, betweenColumnsPadding],
            pr: [0, betweenColumnsPadding, betweenColumnsPadding],
            pb: [1, 0],
          }}
        >
          <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
            {links.map(link => {
              const { label, ...props } = link
              return (
                <T
                  variant="body2"
                  component="li"
                  key={props?.href || props?.to}
                >
                  <Link dark {...props}>
                    {label}
                  </Link>
                </T>
              )
            })}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={4}
          sx={{ pl: [0, 0, betweenColumnsPadding] }}
        >
          <WhiteT variant="body2">
            {
              "We're happy to help you with any questions you have about your application."
            }
          </WhiteT>
          <T variant="body2">
            <Link dark href={`mailto:${email}`}>
              {email}
            </Link>
          </T>
        </Grid>
      </Grid>
    </FooterLandmark>
  )
}

export default Footer
