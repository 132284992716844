import React, { FC, forwardRef } from "react"
import { default as MuiButton, ButtonProps } from "@mui/material/Button"

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    text: true
  }
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { variant = "contained", ...props },
  ref
) {
  return (
    <MuiButton
      {...props}
      variant={variant}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      ref={ref}
    />
  )
})

Button.propTypes = {
  ...(Button as FC<ButtonProps>).propTypes,
}

export default Button
