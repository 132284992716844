import { AlertsContext } from "../providers/AlertsProvider"
import { Alert } from "@ap-frontend/types"
import { useContext } from "react"
import { useUser } from "../providers/UserProvider"
import usePath from "./usePath"
import { areConditionsMet } from "@ap-frontend/validation"

const alertPathMatches = (
  alertPaths: string[] | undefined,
  path: string | undefined
): boolean => {
  if (alertPaths === undefined || alertPaths.length === 0) return true

  if (path === undefined) return false

  return alertPaths.some(alertPath =>
    new RegExp("^" + alertPath + "$").test(path)
  )
}

const useAlerts = (): Alert[] => {
  const { profile } = useUser()
  const path = usePath()

  return useContext(AlertsContext).filter(
    alert =>
      areConditionsMet(
        // Firestore documents don't support nested arrays
        // So we just support one set of conditions and wrap them in an extra [] here
        // To make them match the expected format
        { conditions: alert.display.conditions && [alert.display.conditions] },
        // Not loading in question answers
        // No clear requirements to need conditions based on that in alerts
        // Reduce unnecessary rerendering.
        { questions: {} },
        profile
      ) && alertPathMatches(alert.display.path, path)
  )
}

export default useAlerts
