import {
  collection,
  query as firestoreQuery,
  orderBy,
} from "firebase/firestore"
import { useFirebase } from "../providers/FirebaseProvider"
import React, { createContext, useEffect, useState } from "react"
import { PhotoSubmissionTaskWithId } from "@ap-frontend/types"
import { onSnapshot } from "firebase/firestore"
import { FCWithChildren } from "../interfaces/core"
import { useUser } from "./UserProvider"

export const PhotoSubmissionsContext = createContext<
  PhotoSubmissionTaskWithId[] | undefined
>(undefined)
PhotoSubmissionsContext.displayName = "PhotoSubmissionsContext"

export const PhotoSubmissionsProvider: FCWithChildren = ({ children }) => {
  const [data, setData] = useState<PhotoSubmissionTaskWithId[] | undefined>(
    undefined
  )

  const { firestore } = useFirebase()
  const { applicationNumber } = useUser()

  useEffect(() => {
    if (!firestore) return
    if (!applicationNumber) return

    const query = firestoreQuery(
      collection(firestore, `applicants/${applicationNumber}/photoSubmissions`),
      orderBy("startedAt", "desc")
    )

    const unsubscribe = onSnapshot(query, QuerySnapshot => {
      const submissions: PhotoSubmissionTaskWithId[] = []
      QuerySnapshot.forEach(doc => {
        submissions.push({
          ...doc.data(),
          id: doc.id,
        } as PhotoSubmissionTaskWithId)
      })
      setData(submissions)
    })

    return () => {
      unsubscribe()
    }
  }, [firestore, applicationNumber])

  return (
    <PhotoSubmissionsContext.Provider value={data}>
      {children}
    </PhotoSubmissionsContext.Provider>
  )
}
