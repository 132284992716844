import {
  collection,
  query as firestoreQuery,
  orderBy,
} from "firebase/firestore"
import { useFirebase } from "../providers/FirebaseProvider"
import React, { useEffect, useState } from "react"
import { ScanAttemptLogWithID } from "@ap-frontend/types"
import { onSnapshot } from "firebase/firestore"
import { FCWithChildren } from "../interfaces/core"
import { useUser } from "./UserProvider"
import { createContext } from "use-context-selector"

export const ScanAttemptsContext = createContext<
  ScanAttemptLogWithID[] | undefined
>(undefined)
ScanAttemptsContext.displayName = "ScanAttemptsContext"

export const ScanAttemptsProvider: FCWithChildren = ({ children }) => {
  const [data, setData] = useState<ScanAttemptLogWithID[] | undefined>(
    undefined
  )

  const { firestore } = useFirebase()
  const { applicationNumber } = useUser()

  useEffect(() => {
    if (!firestore) return
    if (!applicationNumber) return

    const query = firestoreQuery(
      collection(firestore, `applicants/${applicationNumber}/scanAttempts`),
      orderBy("start", "desc")
    )

    const unsubscribe = onSnapshot(query, QuerySnapshot => {
      const scanAttempts: ScanAttemptLogWithID[] = []
      QuerySnapshot.forEach(doc => {
        scanAttempts.push({
          ...doc.data(),
          id: doc.id,
        } as ScanAttemptLogWithID)
      })
      setData(scanAttempts)
    })

    return () => {
      unsubscribe()
    }
  }, [firestore, applicationNumber])

  return (
    <ScanAttemptsContext.Provider value={data}>
      {children}
    </ScanAttemptsContext.Provider>
  )
}
