import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import GlobalStyles from "@mui/material/GlobalStyles"
import CamMuiTheme from "../../src/data/cam-mui-theme"
import { ServiceStatusProvider } from "../../src/providers/ServiceStatusProvider"
import { FCWithChildren } from "../../src/interfaces/core"

const TopLayout: FCWithChildren = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          httpEquiv="Cache-control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta httpEquiv="Pragma" content="no-cache" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700,900&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;500;600&display=swap"
        />
      </Helmet>
      <ThemeProvider theme={CamMuiTheme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            ".firebase-emulator-warning": {
              fontSize: "0",
              background: "rgba(255,80,0,0.8) !important",
              color: "white !important",
              width: "8rem !important",
              padding: "0 0.4rem",
              overflow: "hidden",
              top: 0,
              left: "calc(50% - 4rem) !important",
              border: "none !important",
              bottom: "auto !important",
              fontWeight: "600",
              borderRadius: "0 0 6px 6px",
              "&::before": {
                content: '"Emulating firebase"',
                fontSize: "0.75rem",
              },
            },
            "@media print": {
              body: {
                fontSize: "0.9rem",
              },
              ".firebase-emulator-warning": {
                display: "none !important",
              },
            },
            "@page": {
              size: "A4",
            },
          }}
        />
        <ServiceStatusProvider>{children}</ServiceStatusProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default TopLayout
