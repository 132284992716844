import { SemanticVersion } from "@ap-frontend/types"

export interface VersionDetails {
  current?: SemanticVersion
  revision?: string
}

const useVersion = (): VersionDetails => {
  // Doing as a hook so we could adapt this to pull latest version from service status
  // And do checks on how up-to-date it is.
  const current = (process.env.GATSBY_WEBAPP_VERSION ||
    "0.0.0") as SemanticVersion
  const revision = process.env.GATSBY_WEBAPP_REVISION

  return { current, revision }
}

export default useVersion
