import { useState, useEffect, useCallback } from "react"
import {
  doc,
  DocumentData,
  onSnapshot,
  PartialWithFieldValue,
  setDoc,
  SetOptions,
  SnapshotMetadata,
} from "firebase/firestore"
import { useFirebase } from "../providers/FirebaseProvider"

export type QueryKey = string | null | undefined

export interface QueryReturn<Data> {
  data: Data | undefined
  error: Error | undefined
  mutate: (data: Data, config?: SetOptions) => void
  // metadata can be used to determine if the data is from cache or not
  metadata: SnapshotMetadata | undefined
}

export const useFirestoreDoc = <Data = unknown>(
  key: QueryKey
): QueryReturn<Data> => {
  const [data, setData] = useState<{ [x: string]: Data } | undefined>(undefined)
  const [metadata, setMetadata] = useState<SnapshotMetadata | undefined>(
    undefined
  )
  const [error, setError] = useState<Error | undefined>()
  const firestore = useFirebase(ctx => ctx.firestore)

  useEffect(() => {
    // We return if the key has not resolved to a string
    // This allows us to provide conditionals to the key value and only fetch when they are truthy
    if (!firestore || typeof key !== "string") {
      return
    }
    const docRef = doc(firestore, key)

    const unsubscribe = onSnapshot(docRef, doc => {
      try {
        if (doc.exists()) {
          setData({ [key]: doc.data() as Data })
        } else {
          throw new Error("Document does not exist")
        }

        setMetadata(doc.metadata)

        // Clear any previous errors.
        setError(undefined)
      } catch (e) {
        setError(e as Error)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [firestore, key])

  const mutate = useCallback(
    async (data: Data, config?: SetOptions) => {
      if (!firestore || typeof key !== "string") {
        return
      }

      if (config) {
        await setDoc(
          doc(firestore, key),
          data as PartialWithFieldValue<DocumentData>,
          config
        )
      } else {
        await setDoc(
          doc(firestore, key),
          data as PartialWithFieldValue<DocumentData>
        )
      }
    },
    [key, firestore]
  )
  return { data: key && data ? data[key] : undefined, error, mutate, metadata }
}
