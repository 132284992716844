/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from "react"
import TopLayout from "./TopLayout"
import { GatsbyBrowser } from "gatsby"
import { FirebaseProvider } from "../../src/providers/FirebaseProvider"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "../../src/components/ErrorFallback"
export { wrapPageElement } from "./wrap-page-element"
import CookieBanner from "../../src/components/CookieBanner"
import useServiceStatus from "../../src/hooks/useServiceStatus"
import { UserProvider } from "../../src/providers/UserProvider"
import MaintenanceMode from "../../src/components/layouts/MaintenanceMode"
import { FCWithChildren } from "../../src/interfaces/core"

const MaintenanceWrapper: FCWithChildren = ({ children }) => {
  const serviceStatus = useServiceStatus()

  if (!serviceStatus || !serviceStatus.maintenance) {
    return <>{children}</>
  }

  return <MaintenanceMode />
}

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <FirebaseProvider>
      <TopLayout>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <UserProvider>
            <MaintenanceWrapper>
              <CookieBanner />
              {element}
            </MaintenanceWrapper>
          </UserProvider>
        </ErrorBoundary>
      </TopLayout>
    </FirebaseProvider>
  )
}
