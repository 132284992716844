import { styled } from "@mui/material"
import Box from "@mui/material/Box"

const ButtonWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
}))

export default ButtonWrapper
