import React, { useEffect, useState } from "react"
import {
  FirebaseApp,
  FirebaseOptions,
  getApp,
  initializeApp,
  FirebaseError,
} from "firebase/app"
import {
  Firestore,
  getFirestore,
  initializeFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore"
import {
  getStorage,
  FirebaseStorage,
  connectStorageEmulator,
} from "firebase/storage"
import { getAuth, Auth, connectAuthEmulator } from "firebase/auth"
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector"
import { FCWithChildren } from "../interfaces/core"

export type FirebaseContextType = {
  app: FirebaseApp | undefined
  firestore: Firestore | undefined
  auth: Auth | undefined
  storage: FirebaseStorage | undefined
}

export const FirebaseContext = createContext<FirebaseContextType>({
  app: undefined,
  firestore: undefined,
  auth: undefined,
  storage: undefined,
})
FirebaseContext.displayName = "FirebaseContext"

interface FirebaseProps {
  appName?: string
}

interface EmulatorConfig {
  auth?: string
  firestore?: string
  storage?: string
}

/**
 * Initialise Firebase App, Firebase Auth and Firestore.
 * Distribute them throughout application using a Context Provider
 */
export const FirebaseProvider: FCWithChildren<FirebaseProps> = ({
  children,
  appName,
}) => {
  const [app, setFirebaseApp] = useState<FirebaseApp | undefined>(undefined)
  const [firestore, setFirestore] = useState<Firestore | undefined>(undefined)
  const [storage, setStorage] = useState<FirebaseStorage | undefined>(undefined)
  const [auth, setAuth] = useState<Auth | undefined>(undefined)
  const [emulatorConfig, setEmulatorConfig] = useState<
    EmulatorConfig | undefined
  >(undefined)

  // Setup app
  useEffect(() => {
    // Never rerun if the app already exists.
    if (app) return

    fetch(`/firebase-config.json?t=${new Date().getTime()}`)
      .then(response => response.json())
      .then(firebaseConfigRaw => {
        const { emulators, ...firebaseConfig } = firebaseConfigRaw
        if (emulators) {
          setEmulatorConfig(emulators as EmulatorConfig)
        }

        return initializeApp(firebaseConfig as FirebaseOptions, appName)
      })
      .catch(e => {
        // Hot reload preserves the app instance, so detect this case and use the existing instance
        if (e?.code === "app/duplicate-app") {
          return getApp(appName)
        } else {
          throw e
        }
      })
      .then(app => {
        setFirebaseApp(app)
      })
  }, [appName, app])

  // Setup auth
  useEffect(() => {
    if (!app) return

    let auth

    try {
      auth = getAuth(app)
    } catch (e) {
      console.error(e)
      throw new Error("firebase-provider/auth/get-auth-failed")
    }

    if (emulatorConfig && emulatorConfig.auth) {
      console.log("Emulating firebase auth...")
      try {
        connectAuthEmulator(auth, emulatorConfig.auth)
      } catch (e) {
        console.error(e)
        throw new Error("firebase-provider/auth/failed-to-connect-to-emulator")
      }
    }

    setAuth(auth as Auth)
  }, [app, emulatorConfig])

  // Setup firestore
  useEffect(() => {
    if (!app || !auth) return

    let firestore
    try {
      firestore = initializeFirestore(app, {
        // Cypress breaks firestore's connection so we switch it to long polling when running the tests
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        experimentalForceLongPolling: !!(window as any).Cypress,
      })
    } catch (e) {
      // HMR preserves the app instance, we detect this case and use the existing app instance to get the Firestore instance
      if (e instanceof FirebaseError && e?.code === "failed-precondition") {
        firestore = getFirestore(app)
      } else {
        throw e
      }
    }

    if (!firestore) {
      throw new Error("firebase-provider/firestore/no-firestore-instance")
    }

    if (emulatorConfig && emulatorConfig.firestore) {
      const url = new URL(emulatorConfig.firestore)
      console.log("Emulating firestore...")
      connectFirestoreEmulator(firestore, url.hostname, parseInt(url.port))
    }

    setFirestore(firestore as Firestore)
  }, [auth, app, emulatorConfig])

  // Setup cloud storage
  useEffect(() => {
    if (!app || !auth) return

    let storage

    try {
      storage = getStorage(app)
    } catch (e) {
      console.error(e)
      throw new Error("firebase-provider/storage/get-storage-failed")
    }

    // If we use the default (10m) the file upload will sit with a spinner for too long
    // (if no connectivity). Setting to 30s.
    storage.maxUploadRetryTime = 30000

    if (emulatorConfig && emulatorConfig.storage) {
      console.log("Emulating cloud store...")
      const url = new URL(emulatorConfig.storage)
      try {
        connectStorageEmulator(storage, url.hostname, parseInt(url.port))
      } catch (e) {
        console.error(e)
        throw new Error(
          "firebase-provider/storage/failed-to-connect-to-emulator"
        )
      }
    }

    setStorage(storage)
  }, [auth, app, emulatorConfig])

  return (
    <FirebaseContext.Provider value={{ app, firestore, auth, storage }}>
      {children}
    </FirebaseContext.Provider>
  )
}

type Selector<T> = (ctx: FirebaseContextType) => T

export function useFirebase(): FirebaseContextType
export function useFirebase<T>(selector: Selector<T>): T
export function useFirebase<T>(
  selector?: Selector<T>
): T | FirebaseContextType {
  const [isOriginalSelectorUndefined] = useState(selector === undefined)

  if ((selector === undefined) !== isOriginalSelectorUndefined) {
    throw Error(
      "Can't use switch between an undefined selector and a defined selector, would break the rule of hooks"
    )
  }

  return selector !== undefined
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useContextSelector(FirebaseContext, selector)
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useContext(FirebaseContext)
}
