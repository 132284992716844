import { User, useUser } from "../providers/UserProvider"
import { navigate } from "gatsby"

const useUserWithSignInRedirect: () => User = () => {
  const auth = useUser()
  if (auth.user === null) {
    navigate("/sign-in")
  }

  return auth
}

export default useUserWithSignInRedirect
