import React, { ReactElement, PropsWithChildren } from "react"
import T from "@mui/material/Typography"
import Logo from "../Logo"
import Footer from "../Footer"
import Link from "../Link"
import Box from "@mui/material/Box"
import { visuallyHidden } from "@mui/utils"
import { HomeLink } from "./SignedInLayout"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { AlertsProvider } from "../../providers/AlertsProvider"
import Alerts from "../Alerts"
import NoScript from "../NoScript"
import { HeaderLandmark, LandmarkWrapper, MainLandmark } from "./Landmarks"

export const CustomLogo = (): ReactElement => {
  return (
    <>
      <Logo sx={{ maxWidth: 220, marginBottom: 4 }} />
      <Box sx={visuallyHidden}>Home</Box>
    </>
  )
}

const OnboardingLayout = ({
  children,
  hideLinks,
}: PropsWithChildren<{
  hideLinks?: boolean
}>): ReactElement => {
  return (
    <LandmarkWrapper>
      <HeaderLandmark>
        {hideLinks ? (
          <>
            <CustomLogo />
            <NoScript />
            <AlertsProvider>
              <OnboardingLayout.Alerts />
            </AlertsProvider>
          </>
        ) : (
          <nav>
            <Link to={"/"} data-test-id="logo-link">
              <CustomLogo />
            </Link>
            <NoScript />
            <AlertsProvider>
              <OnboardingLayout.Alerts />
            </AlertsProvider>
            <T variant="body1" sx={{ mb: [3, 5] }}>
              <HomeLink to={"/"} data-test-id="home-link">
                <ArrowBackIcon />
                Home
              </HomeLink>
            </T>
          </nav>
        )}
      </HeaderLandmark>
      <MainLandmark>{children}</MainLandmark>
      <Footer />
    </LandmarkWrapper>
  )
}

OnboardingLayout.Alerts = Alerts

export default OnboardingLayout
