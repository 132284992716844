import { AnswerValue, ID, AnswersSchema } from "@ap-frontend/types"
import { createContext } from "use-context-selector"
import React, { PropsWithChildren, ReactElement, useCallback } from "react"
import { useUser } from "./UserProvider"
import {
  useFirestoreDoc,
  QueryKey,
  QueryReturn,
} from "../hooks/useFirestoreDoc"
import debounce from "lodash.debounce"
import isEqual from "lodash.isequal"
import mapValues from "lodash.mapvalues"
import { data as questions } from "@ap-frontend/questions"
import mergeWith from "lodash.mergewith"
import { FieldValue, SetOptions, serverTimestamp } from "firebase/firestore"

/**
 * A function that replicates firestore's merging logic when applied to _.mergeWith
 */
function firestoreEquivalentMergeWithCustomiser(
  objValue: unknown,
  srcValue: unknown
) {
  // Always overwrite arrays
  if (Array.isArray(objValue)) {
    return srcValue
  }
}

interface DebouncedFirebaseMutatorSet {
  [questionId: string]: ReturnType<typeof debounce>
}

export interface QuestionAnswer {
  data: AnswersSchema | undefined
  update: (questionId: ID, data: AnswerValue) => void
  setViewed: (questionId: ID) => void
}

export const QuestionAnswerContext = createContext<QuestionAnswer>({
  data: undefined,
  update: () => undefined,
  setViewed: () => undefined,
})
QuestionAnswerContext.displayName = "QuestionAnswerContext"

let firstAnswerTimestampHasBeenSet = false
function markFirstAnswerTimestampAsSet() {
  firstAnswerTimestampHasBeenSet = true
}
export function clearFirstAnswerTimestamp(): void {
  firstAnswerTimestampHasBeenSet = false
}
type DeferredAnswersDocMutation = (firstAnswerTimestamp: FieldValue) => void
const deferredFirestoreWrites: Array<DeferredAnswersDocMutation> = []

function hasDeferredFirestoreWritesPending() {
  return deferredFirestoreWrites.length > 0
}

export const useAnswersDoc = (key: QueryKey): QueryReturn<AnswersSchema> => {
  const { data, error, mutate, metadata } = useFirestoreDoc<AnswersSchema>(key)
  if (!firstAnswerTimestampHasBeenSet && data && data.firstAnswerTimestamp) {
    markFirstAnswerTimestampAsSet()
  }
  if (data && data.firstAnswerTimestamp) {
    let deferredCallChain = Promise.resolve()
    while (hasDeferredFirestoreWritesPending()) {
      const fn = deferredFirestoreWrites.shift()
      if (fn != undefined) {
        deferredCallChain = deferredCallChain.then(() =>
          fn(data.firstAnswerTimestamp as FieldValue)
        )
      }
    }
  }
  const wrappedMutate = (answers: AnswersSchema, config?: SetOptions) => {
    const actuallyUpdateFirestore = (firstAnswerTimestamp: FieldValue) => {
      const newDoc = {
        ...answers,
        firstAnswerTimestamp: firstAnswerTimestamp,
        lastAnswerTimestamp: serverTimestamp(),
      }
      markFirstAnswerTimestampAsSet()
      return mutate(newDoc, config)
    }

    const isTryingToUpdateWithoutKnowingCorrectFirstAnswerTimestamp =
      firstAnswerTimestampHasBeenSet && !data?.firstAnswerTimestamp
    if (
      isTryingToUpdateWithoutKnowingCorrectFirstAnswerTimestamp ||
      hasDeferredFirestoreWritesPending()
    ) {
      deferredFirestoreWrites.push(actuallyUpdateFirestore)
    } else {
      if (data && data.firstAnswerTimestamp) {
        actuallyUpdateFirestore(data.firstAnswerTimestamp)
      } else {
        actuallyUpdateFirestore(serverTimestamp())
      }
    }
  }
  return {
    data,
    error,
    mutate: wrappedMutate,
    metadata: metadata,
  }
}

/**
 * Provides a list of answers that the user has entered (which have been saved to firestore).
 * These answers are cached locally and that cache is kept in sync with the remote firestore copy.
 */
export const QuestionAnswerProvider = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => {
  const { applicationNumber, user } = QuestionAnswerProvider.useUser()
  React.useEffect(() => {
    if (!user) {
      clearFirstAnswerTimestamp()
    }
  }, [user])

  const { data: remoteAnswers, mutate } = useAnswersDoc(
    applicationNumber && `applicants/${applicationNumber}/user/answers`
  )

  const [localAnswers, setLocalAnswers] = React.useState<
    AnswersSchema | undefined
  >(undefined)

  /**
   * Stores a separate debounced firestore mutate function for each question.
   */
  const [debouncedFirebaseMutators, setDebouncedFirebaseMutators] =
    React.useState({} as DebouncedFirebaseMutatorSet)

  // Saves the answer to firestore (after 1second without updates)
  const setRemoteAnswer = useCallback(
    (
      questionId: ID,
      answer: AnswersSchema,
      options?: { debounce: boolean }
    ) => {
      if (options?.debounce === false) mutate(answer, { merge: true })

      /**
       * Get or create debounced version of firebase mutate function specific for
       * the question which is being saved
       * */
      let mutator = debouncedFirebaseMutators[questionId]
      if (mutator === undefined) {
        mutator = debounce(
          (ans: AnswersSchema) => mutate(ans, { merge: true }),
          1000
        )
        setDebouncedFirebaseMutators({
          ...debouncedFirebaseMutators,
          [questionId]: mutator,
        })
      }

      mutator(answer)
    },
    [debouncedFirebaseMutators, mutate]
  )

  const setAnswer = useCallback(
    (questionId: ID, value: AnswerValue) => {
      const answer: AnswersSchema = {
        schemaVersion: questions.version,
        questions: {
          [questionId]: {
            value,
            version: (localAnswers?.questions?.[questionId]?.version || 0) + 1,
          },
        },
      }

      setLocalAnswers(
        mergeWith(
          {},
          localAnswers,
          answer,
          firestoreEquivalentMergeWithCustomiser
        )
      )
      // TODO bypass the debounce if the question has an answerJsonSchema.properties.value.enum
      setRemoteAnswer(questionId, answer, { debounce: true })
    },
    [localAnswers, setRemoteAnswer]
  )

  const setViewed = useCallback(
    (questionId: ID) => {
      // No need to set more than once
      if (localAnswers?.questions?.[questionId]?.viewed) {
        return
      }

      const answer: AnswersSchema = {
        schemaVersion: questions.version,
        questions: {
          [questionId]: {
            viewed: true,
            version: (localAnswers?.questions?.[questionId]?.version || 0) + 1,
          },
        },
      }
      setLocalAnswers(
        mergeWith(
          {},
          localAnswers,
          answer,
          firestoreEquivalentMergeWithCustomiser
        )
      )
      // bypass the debounce, otherwise a subsequent call to set the answer overwrites the 'viewed' flag.
      setRemoteAnswer(questionId, answer, { debounce: false })
    },
    [localAnswers, setRemoteAnswer]
  )

  // Update our local state if Firestore sends us snapshot changes
  React.useEffect(() => {
    if (!isEqual(remoteAnswers, localAnswers)) {
      // Prioritise the remote schema version
      const schemaVersions = [
        remoteAnswers?.schemaVersion,
        localAnswers?.schemaVersion,
      ].filter(v => typeof v !== "undefined") as number[]

      if (
        schemaVersions.length === 2 &&
        schemaVersions[0] !== schemaVersions[1]
      ) {
        throw new Error(
          "Schema versions are inconsistent between local and remote."
        )
      }

      // On a slow connection, it is possible for the snapshot to be stale at the point when it received.
      // This is because the user may have made changes between the call to setRemoteAnswers and remoteAnswers updating.
      // To work around this we use a version number to track which answers are most current.
      const mergedAnswers = {
        schemaVersion: schemaVersions.length ? schemaVersions[0] : undefined,
        firstAnswerTimestamp:
          remoteAnswers?.firstAnswerTimestamp ||
          localAnswers?.firstAnswerTimestamp,
        lastAnswerTimestamp:
          remoteAnswers?.lastAnswerTimestamp ||
          localAnswers?.lastAnswerTimestamp,
        questions: {
          // Start with our local changes as this may include answers to questions not yet on the remote
          ...localAnswers?.questions,
          // Add in the answers from either the remote or local copy depending on which has the higher version number
          ...mapValues(remoteAnswers?.questions, (remoteValue, questionId) =>
            (remoteValue.version || -1) >=
            (localAnswers?.questions?.[questionId]?.version || -1)
              ? remoteValue
              : // localAnswers is never undefined.
                // If it was then the ternary operator would pick remoteValue (above).
                (localAnswers as AnswersSchema).questions[questionId]
          ),
        },
      }
      setLocalAnswers(mergedAnswers)
    }
    // Deliberately missing localAnswers, we only care if remoteAnswers changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteAnswers])

  return (
    <QuestionAnswerContext.Provider
      value={{ data: localAnswers, update: setAnswer, setViewed }}
    >
      {children}
    </QuestionAnswerContext.Provider>
  )
}

// Setting these as static members allows us to mock during component testing
QuestionAnswerProvider.useUser = useUser
