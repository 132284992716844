module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Cambridge Application","short_name":"mycapp","start_url":"/","background_color":"#FEFFFE","theme_color":"#098283","display":"minimal-ui","icon":"src/images/UniversityOfCambridge_Shield-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9c444638a0d5bd894b8d99b853ca28e7"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.6_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["REPLACE-GA-TRACKING-ID"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"respectDNT":true,"head":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@4.9.5_webpack-dev-server@3.11.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
