import * as React from "react"
import Button from "../components/design-system/Button"
import T from "@mui/material/Typography"
import ButtonWrapper from "./ButtonWrapper"
import { email } from "@ap-frontend/questions"
import Link from "./Link"
import { CustomLogo } from "./layouts/OnboardingLayout"
import { MinimalFooter } from "./Footer"
import {
  HeaderLandmark,
  LandmarkWrapper,
  MainLandmark,
} from "./layouts/Landmarks"

const ErrorFallback: React.FC = () => (
  <LandmarkWrapper>
    <HeaderLandmark>
      <CustomLogo />
    </HeaderLandmark>
    <MainLandmark>
      <div role="alert">
        <T variant="h1" component="h1">
          There was an unexpected error.
        </T>
        <T variant="body1">
          We have encountered an unexpected error. Please reload the page and
          try again.
        </T>
        <T variant="body2">
          If the problem persists, please email{" "}
          <Link href={`mailto:${email}`}>{email}</Link>.
        </T>
        <ButtonWrapper>
          <Button
            onClick={() => {
              const path = window.location.pathname.replace(
                new RegExp(/(^\/)|(\/$)/g),
                ""
              )
              if (path === "") {
                window.location.reload()
              } else {
                window.location.assign("/")
              }
            }}
            role="link"
            data-test-button="retry"
          >
            Try again
          </Button>
        </ButtonWrapper>
      </div>
    </MainLandmark>
    <MinimalFooter />
  </LandmarkWrapper>
)

export default ErrorFallback
