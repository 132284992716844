import Container from "@mui/material/Container"
import React, { PropsWithChildren, ReactElement, useEffect } from "react"
import useUserWithSignInRedirect from "../../hooks/useUserWithSignInRedirect"
import NoScript from "../NoScript"
import SignedInLayout from "./SignedInLayout"

const AccountLayout = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => {
  const { admin, user } = AccountLayout.useUserWithSignInRedirect()

  useEffect(() => {
    // Avoid confusion. If impersonating, stop on the account screens.
    // Since your actions are on your own user here.
    if (admin.status.isImpersonating) {
      admin.actions.stopImpersonation()
    }
  }, [admin])

  if (!user) {
    // Loading
    return (
      <Container component="main" sx={{ mt: 6 }}>
        <NoScript />
      </Container>
    )
  }

  return (
    <AccountLayout.SignedInLayout
      showHomeLink
      homeLink={admin.access ? "/admin" : "/application"}
    >
      {children}
    </AccountLayout.SignedInLayout>
  )
}

// Setting these as static members allows us to mock during component testing
AccountLayout.useUserWithSignInRedirect = useUserWithSignInRedirect
AccountLayout.SignedInLayout = SignedInLayout

export default AccountLayout
