import { styled } from "@mui/material"
import React from "react"
import useVersion from "../hooks/useVersion"

const VersionPillBox = styled("span")(({ theme }) => ({
  background: theme.palette.grey[700],
  border: `1px solid ${theme.palette.grey[600]}`,
  borderRadius: "15px",
  padding: theme.spacing(0.5, 1),
  color: theme.palette.common.white,
  letterSpacing: "0.3px",
  fontSize: (theme.typography.subtitle1.fontSize as string).replace(
    "em",
    "rem"
  ),
}))

const VersionPill = (): JSX.Element => {
  const version = useVersion()
  return (
    <VersionPillBox>
      {version.current && `v${version.current}`}{" "}
      {version.revision && <small>(Build #{version.revision})</small>}
    </VersionPillBox>
  )
}

export default VersionPill
