import React, {
  PropsWithChildren,
  ReactElement,
  useState,
  useEffect,
} from "react"
import useUserWithSignInRedirect from "../../hooks/useUserWithSignInRedirect"
import SignedInLayout from "./SignedInLayout"
import { navigate } from "gatsby"
import NoScript from "../NoScript"
import Container from "@mui/material/Container"

const AdminLayout = ({
  showHomeLink = true,
  children,
}: PropsWithChildren<{ showHomeLink?: boolean }>): ReactElement => {
  const { user, admin, applicationNumber } =
    AdminLayout.useUserWithSignInRedirect()
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)

  // Stop impersonating if we're on the admin pages
  useEffect(() => {
    // This extra check stops this immediately resetting impersonation after it's started
    if (firstLoadComplete) return

    if (user && admin) {
      if (admin.status.isImpersonating) {
        admin.actions.stopImpersonation()
      }
      setFirstLoadComplete(true)
    }
  }, [user, admin, firstLoadComplete, applicationNumber])

  if (!user) {
    // Show loading.
    return (
      <Container component="main" sx={{ mt: 6 }}>
        <NoScript />
      </Container>
    )
  }

  if (!admin.access) {
    navigate("/404")
    return <></>
  }

  return (
    <AdminLayout.SignedInLayout showHomeLink={showHomeLink} homeLink="/admin">
      {children}
    </AdminLayout.SignedInLayout>
  )
}

// Setting these as static members allows us to mock during component testing
AdminLayout.useUserWithSignInRedirect = useUserWithSignInRedirect
AdminLayout.SignedInLayout = SignedInLayout

export default AdminLayout
