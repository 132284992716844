import { Box, Container, styled, SxProps, Theme } from "@mui/material"
import React from "react"
import { FCWithChildren } from "../../interfaces/core"

export const LandmarkWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "stretch",
  minHeight: "100vh",
}))

export const HeaderLandmark: FCWithChildren<{ sx?: SxProps<Theme> }> = ({
  children,
  sx,
  ...rest
}) => (
  <Container
    sx={{
      pt: 6,
      mb: 4,
      ...sx,
    }}
    component="header"
    maxWidth="md"
    {...rest}
  >
    {children}
  </Container>
)

export const MainLandmark: FCWithChildren<{ sx?: SxProps<Theme> }> = ({
  children,
  sx,
  ...rest
}) => (
  <Container
    component="main"
    sx={{
      mb: [8, 12],
      flexGrow: 1,
      ...sx,
    }}
    maxWidth="md"
    id="main-content"
    {...rest}
  >
    {children}
  </Container>
)

export const FooterLandmark: FCWithChildren<{ sx?: SxProps<Theme> }> = ({
  children,
  sx,
  ...rest
}) => (
  <Box
    component="footer"
    sx={{
      bgcolor: "grey.800",
      color: "common.white",
      displayPrint: "none",
      ...sx,
    }}
    {...rest}
  >
    <Container maxWidth="md" sx={{ py: 4 }}>
      {children}
    </Container>
  </Box>
)
