import React, { useEffect, useState } from "react"
import Button from "./design-system/Button"
import T from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import Modal from "@mui/material/Modal"
import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import Link from "./Link"
import Slide from "@mui/material/Slide"
import Checkbox from "./design-system/Checkbox/Checkbox"
import { useCookies } from "react-cookie"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { alpha } from "@mui/material/styles"
import ButtonWrapper from "./ButtonWrapper"

const WhiteT = styled(T)(({ theme }) => ({
  color: theme.palette.common.white,
}))

const ModalContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[900],
  marginBottom: "-1px",
  paddingBottom: theme.spacing(4),
}))

const ContentContainer = styled(Box)(({ theme }) => ({
  maxWidth: "580px",
  paddingTop: theme.spacing(5),
  [theme.breakpoints.only("xs")]: {
    paddingTop: theme.spacing(2),
  },
}))

const ScrollButtonContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "-1px",
  left: 0,
  right: 0,
  zIndex: theme.zIndex.modal + 1,
  backgroundColor: theme.palette.grey[900],
  background: `linear-gradient(0deg, ${alpha(
    theme.palette.grey[900],
    1
  )} 0%, ${alpha(theme.palette.grey[900], 0.8)} 60%, ${alpha(
    theme.palette.grey[900],
    0
  )} 100%)`,
}))

const CookieBanner: React.FC = () => {
  // Setup the cookies
  const [cookies, setCookie] = useCookies([
    "uoc_cookie_choices",
    "uoc_cookie_choices-version",
    "uoc_cookie_choices-categories",
  ])

  // Parse the categories cookie
  let setCategories: string[] = []
  if (cookies["uoc_cookie_choices-categories"]) {
    try {
      if (typeof cookies["uoc_cookie_choices-categories"] !== "object")
        throw new Error("categories-cookie-not-valid-object")

      if (!Array.isArray(cookies["uoc_cookie_choices-categories"]))
        throw new Error("categories-cookie-not-an-array")

      setCategories = cookies["uoc_cookie_choices-categories"]
    } catch (e) {
      console.error(
        `Unexpected uoc_cookie_choices-categories cookie value: ${e}`
      )
    }
  }

  const [agreePersonalisation, setAgreePersonalisation] = useState(
    setCategories.includes("personalisation_settings")
  )

  const [agreeSocialMarketing, setAgreeSocialMarketing] = useState(
    setCategories.includes("social_marketing_preferences")
  )

  // Show the banner if they haven't accepted it or the version doesn't match
  const hasDismissedCookieBanner =
    ["2", "1", "0"].includes(cookies["uoc_cookie_choices"]) &&
    cookies["uoc_cookie_choices-version"] === "0.1.1"

  const dismissCookieBanner = () => {
    const expires = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    )
    const cookieConfig = {
      domain: new RegExp(/^(.+\.)*cam\.ac\.uk$/g).test(window.location.hostname)
        ? ".cam.ac.uk"
        : undefined, // Don't try to set to cam.ac.uk domain if not on it
      expires,
    }

    const categories = []
    if (agreePersonalisation) categories.push("personalisation_settings")
    if (agreeSocialMarketing) categories.push("social_marketing_preferences")

    setCookie(
      "uoc_cookie_choices",
      // If they don't pick any of the options we set that to "disagreed", i.e. 0.
      categories.length === 0 ? "0" : "2",
      cookieConfig
    )
    setCookie("uoc_cookie_choices-version", "0.1.1", cookieConfig)
    setCookie(
      "uoc_cookie_choices-categories",
      decodeURIComponent(JSON.stringify(categories)),
      cookieConfig
    )
  }

  const [bannerHeight, setBannerHeight] = useState<number | undefined>()
  const [windowHeight, setWindowHeight] = useState<number | undefined>()
  const [scrolledTo, setScrolledTo] = useState<"top" | "bottom">("top")
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const modal = document.getElementById("cookie-banner-modal")

      if (modal) {
        setBannerHeight(modal.scrollHeight)
        setWindowHeight(window.innerHeight)
      }
    }

    window.addEventListener("resize", handleResize)

    // Trigger this to run again once the banner has been put out on the page.
    // So it actually detects the height
    if (!rendered) {
      setRendered(true)
    } else {
      handleResize()
    }

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [setBannerHeight, setWindowHeight, rendered])

  const showScrollButtons =
    typeof bannerHeight !== "undefined" &&
    typeof windowHeight !== "undefined" &&
    bannerHeight > windowHeight

  return (
    <Modal
      open={!hasDismissedCookieBanner}
      aria-labelledby="cooker-banner-title"
      aria-describedby="cooker-banner-description"
      id="cookie-banner-modal"
      sx={{
        top: "auto",
        bottom: "-1px",
        marginBottom: "-1px",
        maxHeight: scrolledTo === "top" ? "calc(100% - 2rem)" : "auto",
      }}
    >
      <Slide in={!hasDismissedCookieBanner} direction="up">
        <ModalContainer tabIndex={-1}>
          <Container role="dialog" sx={{ pb: showScrollButtons ? 6 : 0 }}>
            {showScrollButtons && (
              <ScrollButtonContainer id="modal-scroll-container">
                <Button
                  id="modal-scroll-button"
                  variant="text"
                  size="large"
                  sx={{
                    width: "100%",
                    color: "white",
                    pt: scrolledTo === "bottom" ? 2 : 6,
                    pb: 2,
                  }}
                  onClick={() =>
                    setScrolledTo(scrolledTo === "top" ? "bottom" : "top")
                  }
                >
                  {scrolledTo === "top" ? "Read more" : "Back to top"}
                  {scrolledTo === "top" ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </Button>
              </ScrollButtonContainer>
            )}
            <ContentContainer>
              <WhiteT variant="h2" id="cookie-banner-title">
                Your cookie choices
              </WhiteT>
              <WhiteT variant="body2">
                Cookies are little files that we save on your device to remember
                your preferences. We use necessary cookies to make our site
                work. We use site usage measurement cookies to analyse
                anonymised usage patterns, to make our websites better for you.
              </WhiteT>
              <WhiteT variant="body2">
                You may choose to opt-in to use optional cookies for
                personalisation and to remember your social marketing
                preferences.
              </WhiteT>
              <T variant="body2">
                <Link
                  dark
                  href="https://www.cam.ac.uk/about-this-site/privacy-policy"
                >
                  Give me more information
                </Link>
              </T>
              <Checkbox
                dark
                label="Personalisation settings"
                checked={agreePersonalisation}
                onChange={() => setAgreePersonalisation(!agreePersonalisation)}
                inputProps={{
                  "aria-describedby": "personalisation-description",
                }}
                data-test-id="personalisation-checkbox"
              />
              <WhiteT variant="body2" id="personalisation-description">
                These cookies allow you to tailor your experience.
              </WhiteT>
              <Checkbox
                dark
                label="Social marketing preferences"
                checked={agreeSocialMarketing}
                onChange={() => setAgreeSocialMarketing(!agreeSocialMarketing)}
                inputProps={{
                  "aria-describedby": "social-description",
                }}
                data-test-id="social-checkbox"
              />
              <WhiteT variant="body2" id="social-description">
                These cookies do things like measure how you view media content
                on our website using third-party services and websites.
              </WhiteT>
            </ContentContainer>
            <ButtonWrapper sx={{ mb: { xs: 0, sm: 0 } }}>
              <Button
                id="dismiss-banner-button"
                color="secondary"
                onClick={dismissCookieBanner}
              >
                Save my choices
              </Button>
            </ButtonWrapper>
          </Container>
        </ModalContainer>
      </Slide>
    </Modal>
  )
}

export default CookieBanner
