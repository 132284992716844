import React, { useEffect } from "react"
import { useUser } from "../../providers/UserProvider"
import T from "@mui/material/Typography"
import { CustomLogo } from "./OnboardingLayout"
import Alerts from "../Alerts"
import Link from "../Link"
import { MinimalFooter } from "../Footer"
import { email } from "@ap-frontend/questions"
import { AlertsProvider } from "../../providers/AlertsProvider"
import { navigate } from "gatsby"
import { HeaderLandmark, LandmarkWrapper, MainLandmark } from "./Landmarks"

const MaintenanceMode = (): React.ReactElement => {
  const user = MaintenanceMode.useUser()

  useEffect(() => {
    if (user.user) {
      user.signOut()
    }
  }, [user])

  useEffect(() => {
    navigate("/", { replace: true })
  }, [])

  return (
    <LandmarkWrapper>
      <HeaderLandmark>
        <CustomLogo />
      </HeaderLandmark>
      <MainLandmark>
        <AlertsProvider>
          <Alerts />
        </AlertsProvider>
        <T variant="h1" sx={{ mt: 6 }}>
          My Cambridge Application is temporarily unavailable
        </T>
        <T>
          Sorry, the service is currently down for maintenance. Please check
          back later.
        </T>
        <T>
          If you have an urgent query about your application, you can email{" "}
          <Link href={`mailto:${email}`}>{email}</Link>.
        </T>
        <T>Thank you for your patience.</T>
      </MainLandmark>
      <MinimalFooter />
    </LandmarkWrapper>
  )
}

MaintenanceMode.useUser = useUser

export default MaintenanceMode
