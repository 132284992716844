import React, { PropsWithChildren, ReactElement, createContext } from "react"

export const PathContext = createContext<string | undefined>(undefined)

export const PathProvider = ({
  children,
  path,
}: PropsWithChildren<{ path: string }>): ReactElement => (
  <PathContext.Provider value={path}>{children}</PathContext.Provider>
)
