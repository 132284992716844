import React, { PropsWithChildren, ReactElement } from "react"
import { navigate } from "gatsby"
import useUserWithSignInRedirect from "../../hooks/useUserWithSignInRedirect"
import SignedInLayout from "./SignedInLayout"
import OnboardingLayout from "./OnboardingLayout"
import Skeleton from "../Skeleton"
import usePath from "../../hooks/usePath"
import { ScanAttemptsProvider } from "../../providers/ScanAttemptsProvider"
import { PhotoSubmissionsProvider } from "../../providers/PhotoSubmissionsProvider"

const ApplicationLoadingLayout = (): ReactElement => (
  <ApplicationLayout.OnboardingLayout hideLinks={true}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </ApplicationLayout.OnboardingLayout>
)

const ApplicationLayout = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => {
  const { user, activity, admin, applicationNumber } =
    ApplicationLayout.useUserWithSignInRedirect()

  const path = ApplicationLayout.usePath()

  if (user === undefined) {
    // Still loading
    return <ApplicationLoadingLayout />
  }

  // If an admin user has made it here without impersonating, kick them out
  if (user && admin.access && !admin.status.isImpersonating) {
    if (admin.roles?.audit)
      if (applicationNumber)
        navigate(`/admin/applications/${applicationNumber}`)
      else navigate("/admin/applications")
    else if (admin.roles?.impersonation) navigate("/admin/applications")
    else navigate("/admin")
    return <ApplicationLoadingLayout />
  }

  if (applicationNumber === undefined) {
    // Still loading
    return <ApplicationLoadingLayout />
  }

  // If they haven't completed the onboarding, show the welcome screen
  if (
    user &&
    activity &&
    !activity.completedOnboarding &&
    // Don't redirect if already on the welcome page.
    path !== "application/welcome"
  ) {
    navigate("/application/welcome")
    return <ApplicationLoadingLayout />
  }

  // User isn't an admin user and doesn't have an application number associated
  if (user && !applicationNumber && !admin.access) {
    throw new Error("application/access/unauthorised-user")
  }

  const showHomeLink = path !== "application" && !!activity?.completedOnboarding

  return (
    <ApplicationLayout.SignedInLayout showHomeLink={showHomeLink}>
      <ApplicationLayout.ScanAttemptsProvider>
        <ApplicationLayout.PhotoSubmissionsProvider>
          {children}
        </ApplicationLayout.PhotoSubmissionsProvider>
      </ApplicationLayout.ScanAttemptsProvider>
    </ApplicationLayout.SignedInLayout>
  )
}

// Setting these as static members allows us to mock during component testing
ApplicationLayout.useUserWithSignInRedirect = useUserWithSignInRedirect
ApplicationLayout.OnboardingLayout = OnboardingLayout
ApplicationLayout.SignedInLayout = SignedInLayout
ApplicationLayout.ScanAttemptsProvider = ScanAttemptsProvider
ApplicationLayout.PhotoSubmissionsProvider = PhotoSubmissionsProvider
ApplicationLayout.usePath = usePath

export default ApplicationLayout
