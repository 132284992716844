import React, { FC, forwardRef } from "react"
import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"

export type LinkProps = (
  | MuiLinkProps
  | (MuiLinkProps & GatsbyLinkProps<unknown>)
) & {
  to?: GatsbyLinkProps<unknown>["to"]
  dark?: boolean
  component?: string
  disabled?: boolean
}

const darkStyles = {
  color: "primary.light",
  "&:hover": {
    color: "primary.contrastText",
  },
}

const GatsbyLinkWrapper = forwardRef<
  HTMLAnchorElement,
  GatsbyLinkProps<unknown>
>((props, ref) => {
  // We use innerRef because gatsby-link has a bug: https://github.com/gatsbyjs/gatsby/issues/34325
  return (
    <GatsbyLink
      data-test-id="gatsby-link"
      {...props}
      ref={undefined}
      innerRef={ref}
    />
  )
})
GatsbyLinkWrapper.displayName = "GatsbyLinkWrapper"

const Link: FC<LinkProps> = ({
  children,
  dark = false,
  to,
  href,
  disabled,
  ...props
}) => {
  const styles = dark ? darkStyles : {}
  const classes: string[] = []

  if (disabled && props?.component !== "button") {
    throw new Error("invalid-disabled-state")
  }

  if (disabled) classes.push("Mui-disabled")

  if (typeof to !== "undefined") {
    // An internal link
    const gatsbyProps = { ...props, to } as LinkProps & GatsbyLinkProps<unknown>
    return (
      <MuiLink
        component={GatsbyLinkWrapper}
        sx={styles}
        className={classes.join(" ")}
        {...gatsbyProps}
      >
        {children}
      </MuiLink>
    )
  }

  if (typeof href !== "undefined") {
    // An external ink

    const isAnchorLink = /^#/.test(href)
    const hideNewTabButton = isAnchorLink || /^(mailto|tel):/.test(href)

    return (
      <MuiLink
        {...(isAnchorLink
          ? {}
          : { target: "_blank", rel: "noopener noreferrer" })}
        sx={styles}
        className={classes.join(" ")}
        {...({ ...props, href } as LinkProps)}
      >
        {children}
        {!hideNewTabButton && (
          <OpenInNewIcon
            data-test-id="new-tab-icon"
            sx={{ ml: 0.75, fontSize: "1.2em", verticalAlign: "text-bottom" }}
          />
        )}
      </MuiLink>
    )
  }

  // Link being used as a button
  if (typeof props.onClick !== "undefined" || props?.component === "button") {
    if (props?.component === "button")
      return (
        <MuiLink
          sx={styles}
          className={classes.join(" ")}
          {...props}
          onClick={disabled ? undefined : props?.onClick}
        >
          {children}
        </MuiLink>
      )

    throw new Error("invalid-button")
  }

  // No href or to or onClick...
  throw new Error("invalid-link")
}

export default Link
